import React from "react"
import PropTypes from "prop-types"
import { navItems } from "./blog-nav-bar"
import BlogItself from "./blog-itself"
import MediaGallery from "./media-gallery"
import SchoolSong from "./school-song"
import EigthGradeSpeeches from "./8th-grade-speeches"

function BlogContentSwapper(props) {
  switch (props.current) {
    case navItems.blog.name:
      return <BlogItself visible={props.visible} />
    case navItems.mediaGallery.name:
      return <MediaGallery visible={props.visible} />
    case navItems.schoolSong.name:
      return <SchoolSong visible={props.visible} />
    case navItems.eigthGradeSpeeches.name:
      return <EigthGradeSpeeches visible={props.visible} />
    default:
      return <></>
  }
}

export default BlogContentSwapper

BlogContentSwapper.propTypes = {
  current: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
}
