import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./media-gallery.module.scss"
import { cmsDataHasText, getFormattedDate } from "../../utils"

function NewsItem({ index, newsItem }) {
  const author2 = cmsDataHasText(newsItem.author.text)
    ? newsItem.author.text + ","
    : ""
  const publication2 =
    cmsDataHasText(newsItem.publication.text) + ","
      ? newsItem.publication.text
      : ""
  const date2 = cmsDataHasText(newsItem.date)
    ? getFormattedDate(newsItem.date)
    : ""

  return (
    <div className={styles.news_item} key={index}>
      <a target="_blank" rel="noreferrer" href={newsItem.link.url}>
        <div tabIndex={0} className={styles.news_item_image_container}>
          <GatsbyImage
            className={styles.news_item_image}
            alt={newsItem.image.alt}
            image={newsItem.image.gatsbyImageData}
          />
        </div>
        <h2>{newsItem.title.text}</h2>
        <p>{`${author2} ${publication2}`}</p>
        <p>{date2}</p>
      </a>
    </div>
  )
}

export default NewsItem
