import React from "react"
import * as styles from "./blog.module.scss"
import BlogContentSwapper from "./blog-content-swapper"

function Blog({currentSwapperItem, swapperVisible}) {
  return (
    <div className={styles.container}>
      <span />
      <BlogContentSwapper current={currentSwapperItem} visible={swapperVisible} />
    </div>
  )
}

export default Blog
