import React, { useState } from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Blog from "../components/blog"
import { headerNames } from "../components/navigation/header"
import { navItems } from "../components/blog/blog-nav-bar"

function IndexPage() {
  const [currentSwapperItem, setCurrentSwapperItem] = useState(
    navItems.blog.name
  )
  const [swapperVisible, setSwapperVisible] = useState(true)

  return (
    <Layout
      activeHeader={headerNames.blog}
      setCurrentSwapperItem={setCurrentSwapperItem}
      setSwapperVisible={setSwapperVisible}
    >
      <SEO title="Blog" />
      <Blog
        currentSwapperItem={currentSwapperItem}
        swapperVisible={swapperVisible}
      />
    </Layout>
  )
}

export default IndexPage
