import React from "react"
import * as styles from "./school-song.module.scss"
import { getContentSwapAnimation } from "../../content-swapper-utils"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

function SchoolSong(props) {
  let containerAnimation = getContentSwapAnimation(props.visible)

  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsImageBucket {
        edges {
          node {
            data {
              joe_reilly {
                alt
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)

  const data = query.allPrismicBoggsImageBucket.edges[0].node.data

  return (
    <div className={containerAnimation}>
      <section>
        <div className={styles.container}>
          <h1>School Song</h1>
          <p className={styles.intro_text}>
            Please{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://youtu.be/lDVZa_cEF2g"
            >
              <p className="inline" tabIndex={0}>
                click here
              </p>
            </a>{" "}
            or on the image below to enjoy our school song,
            <br />
            written and sung by our students in collaboration
            <br />
            with the our friend Joe Reilly!
          </p>
          <div className={styles.image_container}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://youtu.be/lDVZa_cEF2g"
            >
              <div tabIndex={0}>
                <GatsbyImage
                  className={styles.image}
                  image={data.joe_reilly.gatsbyImageData}
                  alt={data.joe_reilly.alt}
                />
              </div>
            </a>
          </div>
          {/* <div className={styles.link_out}>
            <p>
              Learn more about{" "}
              <a
                rel="noreferrer"
                target="_blank"
                href="http://www.joereilly.org/"
              >
                <p className="inline" tabIndex={0}>
                  Joe Reilly
                </p>
              </a>
            </p>
          </div> */}
        </div>
      </section>
    </div>
  )
}

export default SchoolSong
