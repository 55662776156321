import { NO_TEXT } from "../../constants"

export const getBlogLink = (formattedDate, title) => {
  const reformatForLink = string => {
    return string
      .toLowerCase()
      .replace(/(\r\n|\n|\r)/gm, " ")
      .replace(/ /g, "")
  }
  return `${reformatForLink(formattedDate)}::${reformatForLink(title)}`
}

export const getBlogImageAltText = imageAltText => {
  return imageAltText ? imageAltText : "No alt text provided for image"
}

export const trimBlogIntroParagraph = (string, limit) => {
  let result = string
  while (result.length > limit) {
    const snip = result.lastIndexOf(" ")
    result = result.substring(0, snip)
  }

  if (result === NO_TEXT) {
    return ""
  } else {
    return `${result}...`
  }
}
