import React from "react"
import * as styles from "../../utils.module.scss"
import PropTypes from "prop-types"

function LoadMoreItems(props) {
  if (props.curr < props.max) {
    return (
      <button onClick={props.loadMore} className={styles.load_more_items}>
        <h1>
          Load more {props.type}
        </h1>
      </button>
    )
  } else {
    return <></>
  }
}

LoadMoreItems.propTypes = {
  loadMore: PropTypes.func.isRequired,
  curr: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
}

export default LoadMoreItems
