import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./blog-itself.module.scss"
import { useStaticQuery, graphql } from "gatsby"

function BlogPortion({ index, title, text, link, image, imageAlt, post }) {
  const query = useStaticQuery(graphql`
    {
      defaultImg: file(relativePath: { eq: "boy_with_sunflower.jpeg" }) {
        childImageSharp {
          gatsbyImageData(width: 500)
        }
      }
    }
  `)

  if (image === null || image === undefined || !image) {
    image = query.defaultImg.childImageSharp.gatsbyImageData
  }

  if (index === 0) {
    return (
      <div className={styles.blog_portion}>
        <div className={styles.blog_item_image_container}>
          <GatsbyImage
            className={styles.blog_item_image}
            alt={imageAlt}
            image={image}
          />
        </div>

        <a href={`#${link}`} title={title}>
          <h1>{title}</h1>
        </a>

        {text && <p>{text}</p>}

        {post &&
          post.raw.map((obj, index) => {
            if (obj.type === "image") {
              return (
                <div key={index} className={styles.external_image}>
                  <img src={obj.url} alt={obj.alt} />
                </div>
              )
            } else {
              const objSpan = obj.spans[0]
              if (objSpan && objSpan.type === "hyperlink") {
                return (
                  <div className={styles.inline_text}>
                    <p>{obj.text.substring(0, objSpan.start - 1)}</p>{" "}
                    <a
                      className={styles.text_decoration}
                      href={objSpan.data.url}
                    >
                      <p>{obj.text.substring(objSpan.start, objSpan.end)}</p>
                    </a>{" "}
                    <p>
                      {obj.text.substring(objSpan.end + 1, obj.text.length)}
                    </p>
                    <br />
                    <br />
                  </div>
                )
              } else {
                return <p>{obj.text}</p>
              }
            }
          })}
      </div>
    )
  }
}

export default BlogPortion
