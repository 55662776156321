import { months, days, hours } from "./constants"
import * as styles from "./utils.module.scss"

export const splitOnNewline = (text) => {
  return text.split(/\r?\n/)
}

export const getFormattedDate = (date) => {
  const dateSplit = date.split("-")

  const formattedDate = `${months[parseInt(dateSplit[1] - 1)]} 
    ${days[parseInt(dateSplit[2].substring(0, 2) - 1)]} 
    ${dateSplit[0]}`

  return formattedDate
}

export const getFormattedDateTime = (date) => {
  const timestamp = Date.parse(date)
  const dateObj = new Date(timestamp)
  const formattedDate = getFormattedDate(date)

  return `${formattedDate} ${convertMilitaryHours(dateObj)}:${safeGetMinutes(dateObj)}${getTimeOfDay(dateObj.getHours())}`
}

const safeGetMinutes = (dateObj) => {
  const minutes = dateObj.getMinutes()
  if(minutes === 0) {
    return "00"
  } else {
    return minutes
  }
}

const convertMilitaryHours = (dateObj) => {
  const hours = dateObj.getHours()
  if(hours > 12) {
    return hours - 12
  } else {
    return hours
  }
}

const getTimeOfDay = (hour) => {
  if (hour < 12) {
    return "AM"
  } else {
    return "PM"
  }
}

export const getGridCssCenteringClass = (index, size, columnsPerRow) => {
  const remainder = size % columnsPerRow

  if (index >= size - remainder) {
    if (remainder === 1) {
      if (columnsPerRow === 2) {
        if (index === size - 1) {
          return styles.center_grid_item_two_columns
        }
      } else if (columnsPerRow === 3) {
        if (index === size - 1) {
          return styles.center_grid_item_three_columns
        }
      }
    } else if (remainder === 2) {
      if (columnsPerRow === 4) {
        if (index === size - 1) {
          return styles.center_grid_item_four_columns_2
        } else {
          return styles.center_grid_item_four_columns_1
        }
      }
    }
  }

  return ""
}

export const cmsDataHasText = (text) => {
  return !(text.toLowerCase() === "none")
}

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value)
}
