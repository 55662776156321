import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./eigth-grade-speeches.module.scss"

function Speech({ intro, speech, image, imageAltText }) {
  const [expanded, setExpanded] = useState(false)

  if (expanded) {
    return (
      <div className={styles.speech}>
        <div className={styles.image_and_intro}>
          <div className={styles.student_image_container}>
            <GatsbyImage
              className={styles.student_image}
              alt={imageAltText}
              image={image}
            />
          </div>
          <div className={styles.intro}>
            {intro.map((obj, index) => {
              return <p key={index}>{obj.text}</p>
            })}
          </div>
        </div>

        {speech.map((obj, index) => {
          return <p key={index}>{obj.text}</p>
        })}
        <button
          onClick={() => setExpanded(false)}
          className={`${styles.open_close_btn} ${styles.border_top_on_open}`}
        >
          <p>Close Speech</p>
        </button>
      </div>
    )
  } else {
    return (
      <div className={styles.speech}>
        <div className={styles.image_and_intro}>
          <div className={styles.student_image_container}>
            <GatsbyImage
              className={styles.student_image}
              alt={imageAltText}
              image={image}
            />
          </div>
          <div className={styles.intro}>
            {intro.map((obj, index) => {
              return <p key={index}>{obj.text}</p>
            })}
          </div>
        </div>
        <button
          onClick={() => setExpanded(true)}
          className={styles.open_close_btn}
        >
          <p>Open Speech</p>
        </button>
      </div>
    )
  }
}

export default Speech
