import React, { useState } from "react"
import * as styles from "./blog-itself.module.scss"
import BlogPortion from "./blog-portion"
import { getBlogLink, trimBlogIntroParagraph } from "./blog-utils"
import { getFormattedDate } from "../../utils"

function BlogItem({ date, title, titleImage, titleImageAlt, post }) {
  const [collapsed, setCollapsed] = useState(true)

  const formattedDate = getFormattedDate(date)
  const link = getBlogLink(formattedDate, title)

  let trimmedBlogText = ""
  for (let i = 0; i < post.raw.length; i++) {
    if (post.raw[i].text !== undefined && post.raw[i].text !== "undefined") {
      trimmedBlogText = trimBlogIntroParagraph(post.raw[i].text, 175)
      i = 99999
    }
  }

  if (collapsed) {
    return (
      <div id={link} className={`${styles.blog_item} ${styles.collapsed}`}>
        <p className={styles.blog_item_date}>{formattedDate}</p>
        <BlogPortion
          index={0}
          title={title}
          text={trimmedBlogText}
          link={link}
          image={titleImage}
          imageAlt={titleImageAlt}
        />
        <div className={styles.blog_item_button}>
          <button tabIndex={0} onClick={() => setCollapsed(false)} aria-label={`Read more about ${title}`}>Read more</button>
        </div>
      </div>
    )
  } else {
    return (
      <div id={link} className={`${styles.blog_item} ${styles.expanded}`}>
        <p className={styles.blog_item_date}>{formattedDate}</p>
        <BlogPortion
          index={0}
          title={title}
          link={link}
          image={titleImage}
          imageAlt={titleImageAlt}
          post={post}
        />
        <div className={styles.blog_item_button}>
          <button tabIndex={0} onClick={() => setCollapsed(true)} aria-label={`Read less about ${title}`}>Read less</button>
        </div>
      </div>
    )
  }
}

export default BlogItem
