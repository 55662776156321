// extracted by mini-css-extract-plugin
export var blog_item = "blog-itself-module--blog_item--EMD9L";
export var blog_item_button = "blog-itself-module--blog_item_button--aY9lo";
export var blog_item_container = "blog-itself-module--blog_item_container--CadS1";
export var blog_item_date = "blog-itself-module--blog_item_date--ZJ9ZK";
export var blog_item_image = "blog-itself-module--blog_item_image--g7u3S";
export var blog_item_image_container = "blog-itself-module--blog_item_image_container--YOx4F";
export var blog_portion = "blog-itself-module--blog_portion--zy2zE";
export var collapsed = "blog-itself-module--collapsed--jgT2I";
export var expanded = "blog-itself-module--expanded--LWEKc";
export var external_image = "blog-itself-module--external_image--NTw8B";
export var inline_text = "blog-itself-module--inline_text--gUMOV";
export var no_image_divider = "blog-itself-module--no_image_divider--GfLxe";
export var text_decoration = "blog-itself-module--text_decoration--gL1so";