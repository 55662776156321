import React, { useEffect, useState } from "react"
import BlogItem from "./blog-item"
import * as styles from "./blog-itself.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import { getContentSwapAnimation } from "../../content-swapper-utils"
import LoadMoreItems from "./load-more-items"

function BlogItself(props) {
  let containerAnimation = getContentSwapAnimation(props.visible)

  const [numBlogs, setNumBlogs] = useState(BLOG_INCREMENT)
  const loadMoreBlogs = () => {
    const next = numBlogs + BLOG_INCREMENT
    setNumBlogs(next)
  }

  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsBlog(sort: {fields: data___date, order: DESC}) {
        edges {
          node {
            data {
              date
              post {
                raw
              }
              title {
                text
              }
              title_image {
                alt
                gatsbyImageData(width: 250, layout: FIXED)
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className={containerAnimation}>
      <section>
        <div className={styles.blog_item_container}>
          <h1>Blog</h1>
          {query.allPrismicBoggsBlog.edges.map((obj, index) => {
            if (index < numBlogs) {
              const {date, title, title_image, post} = obj.node.data

              return (
                <BlogItem
                  key={index}
                  index={index}
                  date={date}
                  title={title.text}
                  titleImage={title_image.gatsbyImageData}
                  titleImageAlt={title_image.alt}
                  post={post}
                />
              )
            }
            return <></>
          })}
        </div>
        <LoadMoreItems
          loadMore={loadMoreBlogs}
          curr={numBlogs}
          max={query.allPrismicBoggsBlog.edges.length}
          type={"blogs"}
        />
      </section>
    </div>
  )
}

export default BlogItself

export const BLOG_INCREMENT = 10
