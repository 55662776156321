import React from "react"
import Speech from "./speech"
import { getContentSwapAnimation } from "../../content-swapper-utils"
import * as styles from "./eigth-grade-speeches.module.scss"
import { useStaticQuery, graphql } from "gatsby"

function EigthGradeSpeeches(props) {
  let containerAnimation = getContentSwapAnimation(props.visible)

  const query = useStaticQuery(graphql`
    {
      allPrismicBoggs8thGradeSpeeches(sort: {fields: data___date, order: DESC}) {
        edges {
          node {
            data {
              date
              image {
                alt
                gatsbyImageData
              }
              title {
                text
              }
              speech {
                raw
              }
              intro {
                raw
              }
            }
          }
        }
      }
    }
  `)


  return (
    <div className={containerAnimation}>
      <section>
        <div className={styles.container}>
          <h1>8th Grade Speeches</h1>
          {query.allPrismicBoggs8thGradeSpeeches.edges.map((nodeContainer, index) => {
            const { intro, speech, image} = nodeContainer.node.data

            return (
              <Speech
                key={index}
                intro={intro.raw}
                speech={speech.raw}
                imageAltText={image.alt}
                image={image.gatsbyImageData}
              />
            )
          })}
        </div>
      </section>
    </div>
  )
}

export default EigthGradeSpeeches
