import React, { useState } from "react"
import * as styles from "./media-gallery.module.scss"
import { getContentSwapAnimation } from "../../content-swapper-utils"
import { useStaticQuery, graphql } from "gatsby"
import NewsItem from "./news-item"
import LoadMoreItems from "../blog-itself/load-more-items"

function MediaGallery(props) {
  let containerAnimation = getContentSwapAnimation(props.visible)

  const [numItems, setNumItems] = useState(10)
  const loadMoreItems = () => {
    const next = numItems + 10
    setNumItems(next)
  }

  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsMediaGallery(sort: {fields: data___date, order: DESC}){
        edges {
          node {
            data {
              author {
                text
              }
              date
              image {
                alt
                gatsbyImageData
              }
              link {
                url
              }
              publication {
                text
              }
              title {
                text
              }
            }
          }
        }
      }
    }
  `)

  const mediaGalleryPosts = query.allPrismicBoggsMediaGallery.edges

  return (
    <div className={containerAnimation}>
      <section>
        <div className={styles.container}>
          <h1>Media Gallery</h1>
          <div className={styles.grid}>
            {mediaGalleryPosts.map((nodeObj, index) => {
              if (index < numItems) {
                const newsItem = nodeObj.node.data
                return (
                  <NewsItem
                    index={index}
                    newsItem={newsItem}
                  />
                )
              }
              return <></>
            })}
          </div>
          <LoadMoreItems
            loadMore={loadMoreItems}
            curr={numItems}
            max={query.allPrismicBoggsMediaGallery.edges.length}
            type={"news posts"}
          />
        </div>
      </section>
    </div>
  )
}

export default MediaGallery
